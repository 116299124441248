



























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { RoleClaims } from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";
import { checkIsMobile } from "@/components/layout/mobileCheck";
import { AppBar, Footer, Navigation } from "@/components/layout";
import TourProgress from "@/components/common/TourProgress.vue";
import SupportFAB from "@/components/common/SupportFAB.vue";
import { chatSettings } from "@/settings";

@Component({
  methods: {
    showSupportFAB() {
      return chatSettings.showSupportFABInsteadChat;
    },
  },
  components: {
    SupportFAB,
    AppBar,
    Navigation,
    Footer,
    TourProgress,
  },
})
export default class App extends Vue {
  @Getter("theme") theme!: any;
  @Getter("active") userActive!: boolean;
  @Getter("appLoading") appLoading!: boolean;
  @Getter("dataLoading") dataLoading!: boolean;
  @Getter("showSnackbar") showSnackbar!: boolean;
  @Getter("snackbarText") snackbarText!: boolean;
  @Getter("dataLoadSuccess") dataLoadSuccess!: boolean;

  @Getter("tenants/freshchatActive") freshchatActive!: boolean;
  @Action("tenants/getFreshchatActive")
  getFreshchatActive!: () => Promise<void>;

  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/loading") profileLoading!: string;
  @Getter("profile/getUserEmail") userEmail!: string;
  @Getter("profile/permissions") permissions!: RoleClaims[];
  @Getter("profile/isAuthenticated") isAuthenticated!: boolean;
  @Getter("profile/userEmailVerified") userEmailVerified!: boolean;
  @Getter("profile/id") id!: string;

  @Action("sessions/getIncompleteSessions") getSessions!: () => Promise<void>;

  @Action("loadEverything") loadEverything!: () => Promise<void>;
  @Action("clearEverything") clearEverything!: () => void;

  showLayout = true;
  isMobile = false;
  navOpened = false;
  showBanner = false;

  VRMode = LocalStorage.getVRMode();

  toggleNav() {
    this.navOpened = !this.navOpened;
  }

  get hideNav() {
    return !this.permissions?.length;
  }

  get backgroundColor() {
    return this.theme.background + "";
  }
  get loaded() {
    return !this.dataLoading && this.dataLoadSuccess;
  }

  get hideFooter() {
    console.log("hideFooter #path", this.$route.path);
    return this.$route.path.startsWith("/sessions");
  }

  refresh() {
    this.loadEverything();
  }
  reload() {
    location.reload();
  }
  clear() {
    this.clearEverything();
    this.$router.replace("/auth/login");
  }

  freshchatDisplayed = false;
  freshchatRetryCount = 0;

  freshchatDisplayWidget() {
    if (chatSettings.showSupportFABInsteadChat || this.VRMode) return;

    const widget = document.querySelector("#fc_frame");
    if (widget)
      if (this.freshchatActive) {
        // Override styles with delayed bottom transition
        if (
          ((widget as any).style.display !== "block" &&
            (widget as any).style.bottom === "32px") ||
          (widget as any).style.bottom === "15px" // freshchat default
        ) {
          (widget as any).style.transition = "none";
          (widget as any).style.display = "block";
          (widget as any).style.bottom = "-100px";
          (widget as any).style.transition = "0.5s bottom";
          const timer = setTimeout(() => {
            (widget as any).style.bottom = "32px";
            clearTimeout(timer);
          }, 900);
        }
      } else {
        (widget as any).style.display = "none";
        (widget as any).style.bottom = "-100px";
      }
  }

  freshchatRetryWidgetDisplayed() {
    if (chatSettings.showSupportFABInsteadChat || this.VRMode) return;

    const widget = document.querySelector("#fc_frame");

    if (widget)
      if (
        (widget as any).style.display === "block" &&
        (widget as any).style.bottom !== "15px" // freshchat default
      ) {
        const timer = setTimeout(() => {
          this.freshchatRetryCount++;
          this.freshchatRetryCount === chatSettings.chatReadyRetries &&
            (this.freshchatDisplayed = true);
          clearTimeout(timer);
        }, 1000);
      }

    return this.freshchatDisplayed;
  }

  @Watch("$route", { immediate: true })
  routeChanged() {
    // hide show layout/banner
    console.log("routeChanged #path", this.$route.path);
    this.showLayout =
      !this.$route.path.startsWith("/auth") &&
      !this.$route.path.startsWith("/legal");
    this.showBanner = !this.userEmailVerified;
    if (
      this.$route.path.startsWith("/auth") ||
      this.$route.path.startsWith("/legal")
    )
      this.showBanner = false;

    // update document title
    const { name } = this.$route;
    document.title = `PREZP${name ? " | " + name : ""}`;

    // clear user on login page
    if (this.$route.path.startsWith("/auth") && this.freshchatActive)
      // console.log(
      //   "routeChanged - clearing user information for logged out user",
      // );
      // we would need to destroy the freshchat widget and to reinitialize it again in order to log out the previous user from freshchat
      // the least we can do is to close the freshchat window
      this.$freshchat.close();

    // display freshchat if active
    if (!chatSettings.showSupportFABInsteadChat) this.freshchatDisplayWidget();
  }

  get freshchatReady() {
    return this.$freshchat.ready;
  }

  // display freshchat if active
  @Watch("freshchatReady")
  freshchatLoaded() {
    if (chatSettings.showSupportFABInsteadChat) return;

    // Poll widget every {chatReadyPollingInterval} and retry {chatReadyRetries} to check if it's really ready,
    // additionally wait 1 second for the widget to initialize smoothly with transition
    const timer = setInterval(() => {
      console.debug(
        "chat ready:",
        this.freshchatDisplayed,
        "retry:",
        this.freshchatRetryCount,
      );
      this.freshchatDisplayWidget();
      this.freshchatRetryWidgetDisplayed();
      if (this.freshchatDisplayed) clearInterval(timer);
    }, chatSettings.chatReadyPollingInterval);
  }

  @Watch("profileLoading", { immediate: true })
  userLoaded() {
    this.showBanner = !this.userEmailVerified;
    if (
      this.$route.path.startsWith("/auth") ||
      this.$route.path.startsWith("/legal")
    )
      this.showBanner = false;
  }

  @Watch("isAuthenticated")
  authStateChange() {
    if (this.isAuthenticated) {
      if (!this.$sessionHub.isStarted()) this.$sessionHub.start();
      if (!this.$notificationHub.isStarted()) this.$notificationHub.start();
    } else {
      this.$sessionHub.stop();
      this.$notificationHub.stop();
    }
  }

  get snackbarStyle() {
    if (this.$route.path === "/sessions/list") return "padding-bottom: 120px;";
    return "padding-bottom: 0px";
  }
  async load() {
    const route = window.location.toString();
    const parts = route.split("//")[1].split("/");
    const path = "/" + parts.slice(1).join("/");
    await this.loadEverything();

    // todo toggle on
    // if (!this.userEmailVerified) this.$router.replace("/auth/onboarding");
    if (!this.isAuthenticated) {
      if (!path.startsWith("/auth") && !path.startsWith("/legal"))
        this.$router.replace("/auth/login");
    } else if (path.startsWith("/auth") && !path.startsWith("/auth/device"))
      // TODO: Add better handling for device auth when user is already logged in
      this.$router.replace("/");
    else if (path !== this.$route.path) this.$router.replace(path);
  }

  interval: any;
  isUploading = false;

  confirmLeave() {
    return window.confirm(`${this.$t("alerts.beforeLeaveUpload")}`);
  }
  confirmStayInUpload() {
    return this.isUploading && !this.confirmLeave();
  }

  // Alert before tab close, reload or external locations (upload doesn't interrupt on route change)
  beforeWindowUnload(e: Event) {
    if (this.confirmStayInUpload()) {
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = Boolean("");
    }
  }

  created() {
    this.load();
    this.isMobile = checkIsMobile();
    this.$vuetify.theme.dark = LocalStorage.getDarkMode();
    this.interval = setInterval(() => this.getSessions(), 15 * 1000);
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  }

  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  }

  async mounted() {
    // freshchat integration
    if (!chatSettings.showSupportFABInsteadChat)
      await this.getFreshchatActive();
  }

  @Watch("id", { immediate: true })
  updateId() {
    // console.log("updateId #freshchatActive", this.freshchatActive, this.id);
    if (this.freshchatActive) this.$freshchat.setExternalId(this.id);
  }

  @Watch("userEmail", { immediate: true })
  updateEmail() {
    // console.log(
    //   "setUserProperties #freshchatActive",
    //   this.freshchatActive,
    //   this.userEmail,
    // );
    if (this.freshchatActive)
      this.$freshchat.setUserProperties({ email: this.userEmail });
  }
}
